@tailwind base;

/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: var(--secondary) var(--primary);
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 15px;
}

*::-webkit-scrollbar-track {
  background: var(--primary);
  border-radius: 5px;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--secondary);
  border-radius: 14px;
  border: 3px solid var(--primary);
}

@tailwind components;
@tailwind utilities;

@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
  white-space : pre-wrap !important;
}

ol {
    list-style: decimal;
    padding: 20px; /* Removes default padding, more space for item number */
}

li {
  margin-bottom: 5px; /* Adds some spacing between each list item */
  padding-left: 5px; /* Adds some spacing between the bullet and the text */
}

table {
  border-collapse: collapse;
  width: 100%;

}

td, th {
  padding: 8px;
  border: 1px solid gray;
  color: black;
}

th {
  background-color: dodgerblue;
  /*border-bottom: 2px solid gray;*/
  color: whitesmoke;
}

tr:nth-child(even) {
  background-color: skyblue;
}


/*dark table*/
.dark table {
  border-collapse: collapse;
  width: 100%;
}

.dark th, .dark td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid darkgray;
  color: whitesmoke;
}

.dark th {
  background-color: #555;
  color: #fff;
  border-bottom: 2px solid #ddd;
}

.dark tr:nth-child(even) {
  background-color: #333;
}

.chatFrame p {
  padding-bottom: 10px;
  word-spacing: 1px;
  line-height: 1.5;

}
.chatFrame section {
  background-color: #F3F4F6;
  padding: 0.75rem;
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
  border-radius: 0.25rem;
  color: #374151;
}

.dark .chatFrame section {
  background-color: #1F2937;
  color: #D1D5DB;
}

.chatFrame eq {
  background-color: #F3F4F6;
  padding: 0.25rem 0.5rem;
  margin-left: 0.25rem;
  border-radius: 0.25rem;
  color: #374151;
}

.dark .chatFrame eq {
  background-color: #1F2937;
  color: #D1D5DB;
}
